import domReady from '@roots/sage/client/dom-ready';
import anime from 'animejs';
import 'waypoints/lib/noframework.waypoints.min.js';
import { OverlayScrollbars } from 'overlayscrollbars';
import $ from 'jquery';

/**
 * Application entrypoint
 */
domReady(async () => {
  if (document.getElementsByClassName('figNum') !== null) {
    Array.from(document.getElementsByClassName('figNum')).forEach(
      function (element, index) {
        element.innerHTML = index + 1;
      }
    )
  }

  if (document.getElementsByClassName('issue-heading') !== null) {
    Array.from(document.getElementsByClassName('issue-heading')).forEach(
      function (element, index) {
        /* eslint-disable*/
        new Waypoint({
          /* eslint-enable*/
          element: element,
          handler: function (dir) {
            let toc = document.getElementById('article-toc');
            let parsed = (index > 0 && dir == "up") ? index - 1 : index;
            let selected = toc.getElementsByClassName('block text-green')[parsed];
            let prev = toc.getElementsByClassName('active')[0];
            if (selected.classList.contains("active")) {
              return;
            } else if (prev) {
              prev.classList.remove('active');
              selected.classList.add('active');
            } else {
              selected.classList.add("active");
            }
          },
          offset: '20%',
          group: 'toc',
        });
      }
    )
  }

  if (document.getElementById('article-toc') !== null && window.innerWidth > 768) {
    var toc = document.getElementById('article-toc');
    var content = document.getElementsByClassName('e-content')[0];
    var heroH = document.getElementsByClassName('issue-header')[0].scrollHeight;

    Array.from(toc.getElementsByClassName('block text-green')).forEach(
      function (element) {
        element.addEventListener('click', () => {
          if (element.classList.contains('active')) {
            return;
          } else {
            if (toc.getElementsByClassName('active')) {
              toc.getElementsByClassName('active')[0].classList.remove('active');
            }
            element.classList.add('active');
          }
        })
      }
    )

    var tocShift = (direction) => {
      let shift = anime({
        targets: '#article-toc',
        easing: 'easeInOutQuad',
        autoplay: false,
        translateY: [0, -heroH],
        direction: direction,
        complete: function () {
          if (direction == "reverse") {
            toc.style.transition = "opacity 0.5s ease-out";
            toc.style.position = "absolute";
          }
          toc.style.transition = "opacity 1s ease-out";
          toc.style.opacity = "1";
        },
      });

      shift.play();
    }

    /* eslint-disable*/
    var tocWP = new Waypoint({
      /* eslint-enable*/
      element: content,
      handler: function (direction) {
        toc.style.transition = "unset";
        toc.style.opacity = "0";
        if (direction == "down") {
          toc.style.position = "fixed";
          tocShift('normal');
        } else {
          tocShift('reverse');
        }
      },
      offset: -toc.clientHeight,
    });

    if (document.querySelectorAll('sup').length && document.querySelector('#footnotes-list')) {
      document.querySelectorAll('sup').forEach(
        function (element, index) {
          const footnote = document.createElement('div');
          footnote.innerHTML = document.querySelectorAll('#footnotes-list li')[index].innerHTML;
          footnote.classList = "citation";
          element.appendChild(footnote);

          element.addEventListener('mouseover', () => {
            footnote.style.display = "block";
          })
          footnote.addEventListener('mouseout', () => {
            footnote.style.display = "none";
          })
        }
      )
    }
  }

  if (document.getElementsByClassName('post-slider-container') !== null) {
    Array.from(document.getElementsByClassName('post-slider-container')).forEach(
      function (element) {
        let sliderHeight = element.getElementsByClassName('post-slider')[0].clientHeight;
        let sliderWidth = element.clientWidth;
        let totalWidth = element.querySelector('.inline-block.whitespace-normal').clientWidth * element.querySelectorAll('.inline-block.whitespace-normal').length;
        OverlayScrollbars(element.querySelector('.post-slider'), {
          overflow: {
            y: 'visible',
          },
          scrollbars: {
            theme: 'os-theme-light',
          },
        })
        if (totalWidth > sliderWidth) {
          element.querySelector('.os-scrollbar-horizontal').style.display = "block";
          element.querySelector('.post-slider').style.paddingTop = "25px";
        } else {
          element.querySelector('.os-scrollbar-horizontal').style.display = "none";
          element.querySelector('.post-slider').style.paddingTop = "0";
        }

        element.querySelectorAll('.post-slider .inline-block.whitespace-normal').forEach(
          function (item) {
            item.style.height = sliderHeight + 'px';
          }
        )
      }
    )
    window.addEventListener('resize', () => {
      Array.from(document.getElementsByClassName('post-slider-container')).forEach(
        function (element) {
          let sliderWidth = element.clientWidth;
          let totalWidth = element.querySelector('.inline-block.whitespace-normal').clientWidth * element.querySelectorAll('.inline-block.whitespace-normal').length;
          if (totalWidth > sliderWidth) {
            element.querySelector('.os-scrollbar-horizontal').style.display = "block";
            element.querySelector('.post-slider').style.paddingTop = "25px";
          } else {
            element.querySelector('.os-scrollbar-horizontal').style.display = "none";
            element.querySelector('.post-slider').style.paddingTop = "0";
          }
        }
      )
    })
  }

  document.getElementById('nav-search-btn').addEventListener('click', (e) => {
    e.preventDefault();
    let searchbar = document.getElementById('search-container');
    searchbar.classList.remove('hidden');
    searchbar.classList.add('shown');
    searchbar.style.display = "block";
  })

  document.getElementById('close-search').addEventListener('click', (e) => {
    e.preventDefault();
    let searchbar = document.getElementById('search-container');
    searchbar.classList.remove('shown');
    searchbar.classList.add('hidden');
    searchbar.style.display = "none";
  })

  document.getElementById('nav-toggle').addEventListener('click', (e) => {
    e.preventDefault();
    let menu = document.getElementById('mobile-nav');
    if (menu.style.display == "block") {
      e.target.parentNode.querySelector('.close').style.display = "none";
      e.target.parentNode.querySelector('.open').style.display = "block";
      menu.style.display = "none";
    } else {
      e.target.parentNode.querySelector('.close').style.display = "block";
      e.target.parentNode.querySelector('.open').style.display = "none";
      menu.style.display = "block";
    }
  })

  document.getElementById('mobile-search-btn').addEventListener('click', (e) => {
    e.preventDefault();
    var siblings = e.target.parentNode.parentNode.children;
    Array.from(siblings).forEach(function (sibling) {
      if (sibling.classList.contains('hidden')) {
        sibling.classList.remove('hidden');
        sibling.classList.add('shown');
        sibling.style.display = "inline-block";
      } else if (sibling.classList.contains('shown')) {
        sibling.classList.remove('shown');
        sibling.classList.add('hidden');
        sibling.style.display = "none";
      }
    })
  })

  document.getElementById('mobile-searchbar').addEventListener('mouseleave', (e) => {
    e.preventDefault();
    e.target.classList.remove('shown');
    e.target.classList.add('hidden');
    e.target.style.display = "none";
  })

  if (document.getElementsByClassName('author-toggle').length) {
    Array.from(document.getElementsByClassName('author-toggle')).forEach(
      function (element) {
        element.addEventListener('click', (e) => {
          e.preventDefault();
          let author = e.target.dataset.author;
          let elem = document.getElementById(`author-${author}`);
          let container = document.getElementById('author-modal');

          elem.classList.remove('hidden');
          elem.classList.add('showing');
          elem.style.display = "flex";
          container.classList.remove('hidden');
          container.style.display = "block";
        })
      }
    )

    document.getElementById('author-modal').addEventListener('mouseleave', (e) => {
      e.preventDefault();
      hideAuthor();
    })

    document.getElementById('author-close').addEventListener('click', (e) => {
      e.preventDefault();
      hideAuthor();
    })

    var hideAuthor = () => {
      let card = document.querySelector('.author-card.showing');
      let container = document.getElementById('author-modal');
      card.style.display = "none";
      card.classList.remove('showing');
      card.classList.add('hidden');
      container.style.display = "none";
      container.classList.add('hidden');
    }
  }

  const url = new URL(window.location.href);

  document.getElementById('vp_search').addEventListener('submit', (e) => {
    e.preventDefault();
    var s = e.target.querySelector('input[name$="keyword"]');
    var query = s.value;
    url.searchParams.set('keyword', query);
    window.history.replaceState(null, null, url);
    searchSite();
  });

  var searchSite = () => {
    var search = {
      action: 'search_posts',
      s: url.searchParams.get('keyword'),
    }

    console.log(search);

    $.ajax({
      type: 'GET',
      data: search,
      /* eslint-disable*/
      url: ajax_data.ajax_url,
      /* eslint-enable*/
      success: function (res) {
        document.getElementById('search-results').innerHTML = res;
      },
      error: function () {
        console.log('ajax failed');
      },
    })
  }
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
